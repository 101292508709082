export const useMouseEvents = () => {

  const eventHandlers = [];

  const onMouseUp = (eventHandler) => {
    addEventHandler('mouseup',eventHandler);
    addEventHandler('touchend',eventHandler);
  }

  const onMouseMove = (eventHandler) => {
    addEventHandler('mousemove', eventHandler);
    addEventHandler('touchmove', eventHandler);
  }

  const addEventHandler = (type, eventHandler) => {
    if(process.server) return;
    eventHandlers.push({
      handler: eventHandler,
      type: type
    });

    window.addEventListener(type, eventHandler);
  }

  onUnmounted(() => {
    eventHandlers.forEach(eventHandler => {
      window.removeEventListener(
        eventHandler.type,
        eventHandler.handler
      )
    });
  })

  return {
    onMouseUp,
    onMouseMove
  }

}
